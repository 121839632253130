<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="now">你当前正在</div>
            <el-steps :active="active" align-center>
                <el-step title="提交需求"></el-step>
                <el-step title="接单"></el-step>
                <el-step title="签署合同"></el-step>
                <el-step title="申请付款"></el-step>
                <el-step title="服务方工作"></el-step>
                <el-step title="验收成果"></el-step>
                <el-step title="确认收款信息"></el-step>
                <el-step title="交易完成"></el-step>
            </el-steps>
            <div class="state" v-if="active==1">
                <img src="../../assets/images/serve/gth.png" alt="" />
                正在接单
            </div>
            <div class="state" v-if="active==2">
                <img src="../../assets/images/serve/gth.png" alt="" />
                正在上传合同
            </div>
            <div class="state" v-if="active==3">
                <img src="../../assets/images/serve/gth.png" alt="" />
                请输入服务金额
            </div>
            <div class="state" v-if="active==4">
                <img src="../../assets/images/serve/gth.png" alt="" />
                等待对方付款
            </div>
            <div class="state" v-if="active==5">
                <img src="../../assets/images/serve/gth.png" alt="" />
                上传验收成果
            </div>
            <div class="state" v-if="active==6">
                <img src="../../assets/images/serve/gth.png" alt="" />
                确认收款
            </div>
            <div class="state" v-if="active==7">
                <img src="../../assets/images/serve/gth.png" alt="" />
                交易完成
            </div>
            <div class="state" v-if="active==8">
                <img src="../../assets/images/serve/gth.png" alt="" />
                交易完成
            </div>
            <!-- 提交需求 -->

            <!-- 接单  正在接单-->
            <div v-show="active == 1 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <div class="accept" v-if="itemInfo.state != 9">
                    <span>*我接受</span>
                    <el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea" readonly="" v-model="itemInfo.buyDemand"></el-input>
                </div>
                <!-- 底部按钮 -->
                <div v-if="obtain == 0">
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders" @click="getOrders">确认接单</el-button>
                            <div class="give-up" @click="refuseFun">拒绝接单</div>
                        </div>
                    </div>
                </div>

                <!-- 只读拒绝 -->
                <div v-if="itemInfo.state == 9">
                    <div class="wait">
                        商家已拒绝接单!可以去看看<span class="red">服务中心</span> 其他合适的店铺
                    </div>
                </div>
            </div>
            <!-- 签署合同  正在上传合同 -->
            <div v-show="active == 2 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        待填写
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        {{ itemInfo.calssText }} | {{ itemInfo.buyDemand }}
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span> {{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <span class="red">待上传</span>
                    </div>
                </div>
                <div class="upload">
                    <div class="lable">*上传合同附件</div>
                    <el-upload :limit="1" :action="action" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="blue">重要提示：</div>
                <div>
                    买家对此处上传合同协议不认可时可以选择联系人入驻商户客服人员更换其他第三方备案的电子合同协议进行后补签署，也可以后补签署纸质合同协议，如需邮寄合同协议由双方
                    自行协商约定并支付相应的邮寄费用
                </div>
                <!-- 底部按钮 -->
                <div>
                    <div class="foot-btn">
                        <el-button class="orders" @click="uploadContract">下一步</el-button>
                    </div>
                </div>
            </div>
            <!-- 付款 请输入服务金额-->
            <div v-show="active == 3 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        待填写
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        {{ itemInfo.calssText }} | {{ itemInfo.buyDemand }}
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <span class="red">待上传</span>
                    </div>
                </div>

                <div class="hint">*服务金额</div>
                <el-input style="width: 200px" type="number" v-model="value"></el-input>
                <!-- 底部按钮 -->
                <div>
                    <div class="foot-btn">
                        <!-- <el-button class="orders" @click="pay">付款</el-button>
                        <div class="give-up">申诉</div> -->
                        <el-button class="orders" @click="payOne">申请付款</el-button>
                        <!-- <div class="give-up" @click="appealOpen">申诉</div> -->
                    </div>
                    <div class="hui"></div>
                    <div class="hui">
                        点击【申请付款】后待需求方将订单款型发至平台，待验收成果后付款至此账户。
                    </div>
                </div>
            </div>
            <!-- 服务方工作  等待对方付款-->
            <div v-show="active == 4 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        ￥{{ itemInfo.money }}
                    </div>
                    <!-- <div class="cell">
                        <div>我接受</div>知识产权|计算机软件著作权申请
                    </div> -->
                    <div class="cell">
                        <div>联系方式</div>
                        <!-- <div style="color:#2EA7E0">点击获取</div> -->
                        <div style="color: #2ea7e0">{{ itemInfo.shopPhone }}</div>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.contractUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="no-win">
                    <!-- <img src="../../assets/images/serve/gzz.png" alt="">
                    服务方工作中...... -->
                    需求方正在付款的路上,付款后将短信通知你进行工作
                </div>
                <!-- 底部按钮 -->
                <div>
                    <!-- <div class="foot-btn">
                        <el-button class="orders" @click="pay">确认</el-button>
                    </div> -->
                    <div class="foot-btn">
                        <div class="give-up" @click="appealOpen">申诉</div>
                    </div>
                    <!-- <div class="hui">此流程7个工作日后启动跳转至下个流程</div> -->
                </div>
            </div>
            <!-- 验收成果  上传验收成果-->
            <div v-show="active == 5 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        ￥{{ itemInfo.money }}
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        {{ itemInfo.calssText }} | {{ itemInfo.buyDemand }}
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.contractUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="accepting" style="border: none">
                    <div class="cell" v-if="obtain == 1">
                        <div>验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="upload" v-if="obtain == 0">
                    <div class="lable">验收文件</div>
                    <el-upload :limit="1" :action="action" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <!-- 底部按钮 -->
                <div>
                    <!-- <div class="wait"> 请在此流程的三天内进行确认，以方便进行后续工作安排，若三天内未确认次流程，则视为服务方放弃拍价</div> -->
                    <div class="foot-btn" v-if="obtain == 0">
                        <el-button class="orders" @click="acceptance">上传验收成果</el-button>
                        <div class="give-up" @click="appealOpen">申诉</div>
                    </div>
                    <div class="foot-btn" v-else>
                        <el-button class="orders wait-btn">等待验收成果确认</el-button>
                        <div class="give-up" @click="appealOpen">申诉</div>
                    </div>
                    <!-- <div class="foot-btn">
                        <div class="hui">需求方正在付款的路上...</div>
                    </div> -->
                </div>
            </div>
            <!-- 交易中 确认收款-->
            <div v-show="active == 6 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        ￥{{ itemInfo.money }}
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        {{ itemInfo.calssText }} | {{ itemInfo.buyDemand }}
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.contractUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="accepting" style="border: none">
                    <div class="cell">
                        <div>验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="hint">*收款方式</div>
                <div class="payment">
                    <el-radio v-model="radio" :label="0" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                我的钱包
                                <span>余额：{{ info.balance }}元</span>
                            </div>
                        </div>
                    </el-radio>
                    <div>平台</div>
                </div>

                <div class="hint">*收款金额</div>
                <div class="backJd">
                    <div class="ysmoney">{{ itemInfo.money }}</div>
                    <!-- <div style="margin-left:20px">开具发票请自行与服务商协定</div> -->
                </div>
                <!-- 底部按钮 -->
                <div>
                    <div class="foot-btn">
                        <el-button class="orders" @click="payOrder">收款</el-button>
                        <div class="give-up" @click="appealOpen">申诉</div>
                    </div>
                </div>
            </div>
            <!-- 交易完成  交易完成-->
            <div v-show="active == 7 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        ￥{{ itemInfo.money }}
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        <!-- {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }} -->
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.contractUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="accepting" style="border: none">
                    <div class="cell">
                        <div>验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="no-win">
                    <img src="../../assets/images/serve/qr.png" alt="" />
                    交易已完成！需求方暂未评价
                </div>
            </div>
            <div v-show="active == 8 && !represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        ￥{{ itemInfo.money }}
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        {{ itemInfo.calssText }} | {{ itemInfo.buyDemand }}
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.contractUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="accepting" style="border: none">
                    <div class="cell">
                        <div>验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div class="no-win">
                    <img src="../../assets/images/serve/qr.png" alt="" />
                    交易已完成！需求方
                    <span v-if="itemInfo.evaluate == false">暂未评价</span>
                    <span v-if="itemInfo.evaluate == true" style="cursor:pointer;color:rgb(46, 167, 224);" @click="goDetail">需求方评价</span>
                </div>
            </div>
            <!-- 申述 -->
            <div v-if="represent">
                <div class="orderInformation">
                    <div class="cell">
                        <div>订单号</div>
                        <span>{{ itemInfo.id }}</span>
                    </div>
                    <div class="cell">
                        <div>订单名称</div>
                        <span class="red">{{ itemInfo.goodsName }}</span>
                    </div>
                    <div class="cell">
                        <div>下单预算</div>
                        <span>￥{{ itemInfo.buyMoneyBudget }}</span>
                    </div>
                    <div class="cell">
                        <div>服务地区</div>
                        <span>{{ itemInfo.buyNorms }}</span>
                    </div>
                    <div class="cell">
                        <div>需求方</div>
                        <span>{{ itemInfo.buyUserName }}</span>
                    </div>
                    <div class="cell">
                        <div>需求描述</div>
                        <span>{{ itemInfo.buyDemand }}</span>
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span>{{ itemInfo.buyPhone }}（号码保护中）</span>
                    </div>
                </div>
                <!-- 等待中 -->
                <div class="accepting">
                    <div class="cell">
                        <div>服务店铺</div>
                        {{ itemInfo.shopName }}
                    </div>
                    <div class="cell">
                        <div>服务金额</div>
                        待填写
                    </div>
                    <div class="cell">
                        <div>我接受</div>
                        {{ itemInfo.calssText }} | {{ itemInfo.buyDemand }}
                    </div>
                    <div class="cell">
                        <div>联系方式</div>
                        <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ itemInfo.shopPhone }}</span>
                    </div>
                    <div class="cell">
                        <div>合同附件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${itemInfo.contractUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px" class="el-icon-document"></i>
                            <span>下载</span></a>
                    </div>
                </div>
                <div v-if="repreCur == 0">
                    <el-form style="margin-top: 40px" label-position="left" label-width="80px" :model="form" @submit.native.prevent>
                        <el-form-item label="*申诉环节">
                            <div class="code">
                                <el-select v-model="form.state" placeholder="服务环节">
                                    <el-option v-for="item in linkList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item label="*申诉原因">
                            <!-- <div class="code"> -->
                            <el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea" placeholder="50字以内" v-model="form.msg"></el-input>
                            <!-- </div> -->
                        </el-form-item>
                    </el-form>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders" @click="appeal">确定转入申诉</el-button>
                        </div>
                        <div class="hui">
                            申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="orderInformation" style="border: none">
                        <div class="cell">
                            <div>申诉人</div>
                            <span>{{ itemInfo.errorUserName }}</span>
                        </div>
                        <div class="cell">
                            <div>申诉环节</div>
                            <span>{{ errorState[active] }}</span>
                        </div>
                        <div class="cell">
                            <div>申诉原因</div>
                            <span>{{
                itemInfo.errorMsg == "" ? this.form.msg : itemInfo.errorMsg
              }}</span>
                        </div>
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders" disabled v-if="!showAppeal">申诉中</el-button>
                            <el-button class="orders" @click="withdraw" v-if="showAppeal">撤回申诉</el-button>
                            <div class="give-up">平台审核员核实中......</div>
                        </div>
                        <div class="hui">
                            申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Code from "../../components/code.vue";
export default {
    components: { Code },
    props: {
        dialogVisible: Boolean,
        itemInfo: Object,
    },

    data() {
        return {
            showAppeal: false,
            getPhone: false,
            msg: "", //描述
            value: "", // 服务金额
            num: 1,
            active: 1, //当前进度下标
            checked: false, //是否勾选条款合约
            star: 5,
            colors: ["#E40012", "#E40012", "#E40012"],
            obtain: 0, //获取信息 0 为输入信息 1 支付
            smaCode: "", //验证码
            // form: { //
            //     money: '',
            //     phone: '',
            //     smaCode: '',
            // },
            form1: {
                //上传合同
                orderId: this.itemInfo.id,
                fileUrl: "",
            },
            radio: 0, //zhifu
            activeInput: 0,
            captchas: [
                // 密码
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
            ],

            action: this.$util.upload,
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: "",
            isdisabledFn: false,
            checkCode: "", //图形码
            qrCodeShow: false,
            info: {},
            form: {
                //申诉
                state: "",
                msg: "",
                orderId: "",
            },
            represent: false, // 申述
            repreCur: 0, //0正常  1为申诉中
            errorState: {
                4: "服务方工作",
                5: "服务方工作",
                6: "确认收款信息",
            },
            linkList: [],
            token: "",
        };
    },
    watch: {
        active: {
            handler(val) {
                if (val == 4 || val == 5) {
                    this.linkList = [{ id: 4, title: "服务方工作" }];
                } else if (val == 6) {
                    this.linkList = [
                        { id: 4, title: "服务方工作" },
                        { id: 7, title: "确认收款信息" },
                    ];
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        console.log(this.itemInfo)
        this.getInfo();
        this.BooleanRed()
        this.token = sessionStorage.getItem("token");
        if (this.itemInfo) {
            if (this.itemInfo.errorState != 0) {
                this.represent = true;
                this.repreCur = 1;
            }
            if (this.itemInfo.state == 1) {
                this.active = 1;
            } else if (this.itemInfo.state == 2) {
                this.active = 2;
            } else if (this.itemInfo.state == 3) {
                this.active = 3;
            } else if (this.itemInfo.state == 4) {
                this.active = 4;
            } else if (this.itemInfo.state == 5) {
                this.active = 5;
            } else if (this.itemInfo.state == 6) {
                this.active = 5;
                this.obtain = 1;
            } else if (this.itemInfo.state == 7) {
                this.active = 6;
            } else if (this.itemInfo.state == 8) {
                this.active = 8;
            } else if (this.itemInfo.state == 9) {
                this.obtain = 1
            }
        }
    },

    methods: {
        //消除红点
        BooleanRed() {
            if (this.itemInfo) {
                if (
                    this.itemInfo.redUid != "" &&
                    this.itemInfo.redUid != null &&
                    this.itemInfo.redUid != undefined
                ) {
                    this.$util
                        .post("/demandPurchase/reade", {
                            orderId: this.itemInfo.id,
                        })
                        .then((res) => {
                            console.log(res);
                        });
                }
            }
        },
        goDetail() {
            var id = this.itemInfo.goodsId
            this.$router.push({ path: "/shopDetail", query: { id } });
        },
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
                if (this.info.id == this.itemInfo.errorUid) {
                    this.showAppeal = true
                } else {
                    this.showAppeal = false
                }
            });
        },
        handleChange(value) {
            console.log(value);
        },

        handleClose(done) {
            this.$emit("close");
        },

        changeCode(value) {
            this.checkCode = value;
        },

        changeImg() {
            this.$refs["ref_validateCode"].draw();
        },
        // 输入密码
        adjust(index) {
            let dom = document.getElementById("captcha" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirection(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captcha" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captcha" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinash(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captcha" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.$emit("finish", code);
                }
            }
        },
        // 输入密码2
        adjusto(index) {
            let dom = document.getElementById("captch" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirectiono(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captch" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captch" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinasho(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captch" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.$emit("finish", code);
                }
            }
        },
        //拒绝接单
        refuseFun() {
            this.$util
                .post("/demandPurchase/refuse", {
                    orderId: this.itemInfo.id,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.$emit("close");
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 接单
        getOrders() {
            this.$util
                .post("/center/shop/accept/order", {
                    orderId: this.itemInfo.id,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.active++;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 上传合同
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.fileUrl = imgs.join(",");
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.fileUrl = imgs.join(",");
        },
        // 上传合同
        uploadContract() {
            this.$util.post("/center/shop/uploadContract", this.form1).then((res) => {
                if (res.code == 200) {
                    this.active++;
                } else if (res.code == 400) {
                    this.$message.error("请上传合同");
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 申请付款
        payOne() {
			if(this.value == ''){
				return this.$message.error('请输入金额')
			}
            this.$util
                .post("/center/shop/setMoney", {
                    orderId: this.itemInfo.id,
                    money: this.value,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.active++;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 验收
        acceptance() {
            if (this.form1.fileUrl == "") {
                this.$message.error("请上传验收文件");
            } else {
                this.$util.post("/center/shop/uploadResult", this.form1).then((res) => {
                    if (res.code == 200) {
                        this.obtain = 1;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        // 收款
        payOrder() {
            this.$util
                .post("/center/shop/collectMoney", {
                    orderId: this.itemInfo.id,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.active++;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 付款
        pay() {
            if (this.active++ > 8) this.active = 0;
        },
        // 申诉打开
        appealOpen() {
            this.represent = true;
        },
        // 申诉
        appeal() {
            this.form.orderId = this.itemInfo.id;
            console.log(this.form);

            this.$util.post("/demandPurchase/error/sell", this.form).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.repreCur = 1;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 撤回申诉
        withdraw() {
            this.$util
                .post("/demandPurchase/error/close", {
                    orderId: this.itemInfo.id,
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.represent = false;
                        this.repreCur = 0;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.red {
    color: #e40012 !important;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}
.blue {
    color: #2ea7e0;
    font-size: 12px;
    // margin-top: 10px ;
    margin-bottom: 30px;
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
// /deep/.el-input__inner {
//     width: 490px;
// }
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

.orderInformation {
    padding: 20px;
    border: 1px solid #eeeeee;
    margin: 30px 0 30px;
    .cell {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        div {
            width: 100px;
        }
        span {
            color: #222222;
            margin-left: 65px;
        }
        .blue {
            font-size: 16px;
            color: #2ea7e0;
        }
    }
}
.accept {
    display: flex;
    font-size: 16px;
    color: #999999;
    span {
        margin-top: 15px;
    }
    div {
        flex: 1;
        height: 160px;
        // border: 1px solid #eeeeee;
        padding: 15px 10px;
        margin-left: 20px;
    }
}
.accepting {
    padding: 20px;
    border: 1px solid #eeeeee;
    .cell {
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
        span {
            color: #e40012;
        }
    }
}
.wait {
    font-size: 18px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
}
.wait-btn {
    padding: 13px 29px;
    background: #d1d1d1 !important;
}
.give-up {
    margin-left: 80px;
    font-size: 18px;
    cursor: pointer;
    color: #e40012;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
.time-out {
    display: flex;
    align-items: center;
    margin: 100px 0;
    color: #ffcc00;
    font-size: 18px;
    justify-content: center;
    img {
        width: 38px;
        height: 32px;
        margin-right: 20px;
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}
.orders {
    // margin-top: 90px;
    // width: 247px;
    // height: 50px;
    padding: 13px 100px;
    background: #e40012;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    // margin: 90px 0 0;
}
.upload {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 30px 0;
    color: #999999;
    .lable {
        margin-right: 20px;
    }
}
/deep/.el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 100px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
}
/deep/.el-form-item__label {
    line-height: normal;
}
.l-hint {
    div {
        color: #2ea7e0;
        margin-bottom: 10px;
    }
    font-size: 14px;
    color: #222222;
}
.hui {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 20px;
    margin-bottom: 30px;
}
.tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    line-height: normal !important;
    div {
        padding: 6px 14px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        margin-right: 10px;
        // margin-bottom: 10px;
        cursor: pointer;
    }
    .active {
        padding: 6px 14px;
        border: 1px solid #e40012;
        color: #e40012;
    }
}
.backJd {
    margin: 20px 0;
    color: #222;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.reason {
    display: flex;
    color: #222;
    align-items: center;
    margin-bottom: 20px;
    span {
        margin-right: 10px;
        color: #999999;
        font-size: 16px;
    }
}
.foot-content {
    margin: 20px 0;
    .cell {
        color: #222222;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
    }
}
.qr-code {
    width: 257px;
    height: 257px;
    margin: 40px auto;
    background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.evaluation {
    padding: 20px 0;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    color: #222;
}
.evaluation-info {
    margin: 30px 0 50px;
    font-size: 16px;
    .cell {
        display: flex;
        color: #222;
        align-items: center;
        margin-bottom: 30px;
        .title {
            width: 100px;
            color: #999;
            margin-right: 20px;
        }
    }
}
</style>
