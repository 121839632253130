<template>
	<div class="main-box">
		<div class="shop-box">
			<div class="chat-img">
				<!-- <img src="../../assets/images/serve/wechat.png" alt=""> -->
			</div>
			<div class="shop-info">
				<div class="shop-img">
					<img :src="$util.host + shop.headPic" alt="" />
				</div>
				<div style="flex: 1">
					<div class="shop-title">
						<div class="shop-name">{{ shop.name }}</div>
						<img style="width: 33px; height: 22px" src="../../assets/images/serve/qilogo.png" alt=""
							v-if="shop.type == '2'" />
						<div class="shop-tag">
							<div class="bor">
								近一年成交率: <span>{{ shop.overRate }}%</span>
							</div>
							<div class="bor">
								好评率: <span>{{ shop.evaluateRate }}%</span>
							</div>
							<div>
								近一年发布量: <span>{{ shop.allGoodsSize }}单</span>
							</div>
						</div>
						<div>开店时间 {{ shop.createTime }}</div>
						<!-- <img class="dw" src="../../assets/images/serve/dw.png" alt="">
                        <div>广东深圳</div> -->
					</div>
					<div class="shop-business">
						<div>业务： {{ shop.msg }}</div>
					</div>
					<div class="shop-business" v-if="shop.shopClassify">
						<div>
							经营类别：<span v-for="item in shop.shopClassify">{{ item.littleItems[0].name }} |
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mian">
			<div class="order-info">
				<div class="order-number">
					<div class="quantity">
						<div>
							<img style="width: 30px; height: 35px" src="../../assets/images/manage/order.png" alt="" />
							订单量
						</div>
						<span>{{ shop.allSize }}</span>
					</div>
					<div class="quantity">
						<div>
							<img style="width: 30px; height: 24px" src="../../assets/images/manage/link.png" alt="" />
							浏览量
						</div>
						<span>{{ shop.views }}</span>
					</div>
					<div class="quantity">
						<div>
							<img style="width: 28px; height: 31px" src="../../assets/images/manage/succes.png" alt="" />
							成交量
						</div>
						<span>{{ shop.overSize }}</span>
					</div>
					<div class="quantity">
						<div>
							<img style="width: 28px; height: 31px" src="../../assets/images/manage/succes.png" alt="" />
							成交金额
						</div>
						<span>{{ shop.overMoney }}</span>
					</div>
				</div>
				<!-- echarts -->
				<div class="echarts-box">
					<div class="process-box">
						<div class="tabs">
							<el-tabs v-model="activeTitle">
								<el-tab-pane label="流程统计图" name="first">
									<div class="echarts-info">
										<div class="echarts" id="flow-chart"></div>
										<div class="echarts-data">
											<div class="echarts-data-item" v-for="item in echartsInfoList"
												:key="item.id">
												<div></div>
												{{ item.title }}
											</div>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="订单详情统计图" name="second">
									<div class="echarts-info">
										<div class="echarts" id="summary"></div>
										<div class="echarts-data">
											<div class="echarts-data-itemt" v-for="item in echatsSxList" :key="item.id">
												<div></div>
												{{ item.title }}
											</div>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
						<!-- 月订单统计图 -->
						<div class="month-box">
							<div class="month-title">月订单统计图</div>
							<div style="height: 300px; width: 770px" id="foldLine"></div>
						</div>
					</div>
				</div>
			</div>
			<!-- 我的订单 -->
			<div class="main">
				<div class="redIcon">
					<div>
						<span v-show="status1 != 0">{{ status1 }}</span>
					</div>
					<div>
						<span v-show="status2 != 0">{{ status2 }}</span>
					</div>
					<div>
						<span v-show="status3 != 0">{{ status3 }}</span>
					</div>
					<div>
						<span v-show="status4 != 0">{{ status4 }}</span>
					</div>
				</div>
				<div class="tabs-nav">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="全部" name="first"> </el-tab-pane>
						<el-tab-pane label="待接单" name="second"> </el-tab-pane>
						<el-tab-pane label="进行中" name="ww"> </el-tab-pane>
						<el-tab-pane label="已完结" name="ee"> </el-tab-pane>
					</el-tabs>
				</div>
				<!-- 全部 -->
				<div v-show="current == 0">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									订单类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									购买服务日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">购买方账号</div>
						<div class="item132">订单名称</div>
						<div class="item118">预算金额</div>
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom">
								<span class="el-dropdown-link"> 订单状态 </span>
								<el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item171">订单号</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.calssText }}</div>
							<div class="item118">{{ item.buyTime }}</div>
							<div class="item85">{{ item.buyUserName }}</div>
							<div class="item132">{{ item.buyDemand }}</div>
							<div class="item118">￥{{ item.buyMoneyBudget }}</div>
							<div class="item85 red">{{ stateKey[item.state] }}</div>
							<div class="item171">{{ item.id }}</div>
						</div>
					</div>
				</div>
				<!-- 待中标 -->
				<div v-show="current == 1">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									订单类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									购买服务日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">购买方账号</div>
						<div class="item132">订单名称</div>
						<div class="item118">预算金额</div>
						<div class="item85">订单号</div>
						<div class="item171">操作</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.calssText }}</div>
							<div class="item118">{{ item.buyTime }}</div>
							<div class="item85">{{ item.buyUserName }}</div>
							<div class="item132">{{ item.buyDemand }}</div>
							<div class="item118">￥{{ item.buyMoneyBudget }}</div>
							<div class="item85">{{ item.id }}</div>
							<div class="item171" style="display: flex">
								<font class="redBlock" v-if="item.redUid != null && item.redUid.includes(info.id)">
								</font>
								<font class="greyBlock" v-else> </font>
								<span class="blue" @click="readState(item)">查看进度</span>
								<el-popconfirm title="确认删除吗？" @confirm="remove(item.id)">
									<template #reference>
										<!-- @click="remove(item.id)" -->
										<div class="blue">删除</div>
									</template>
								</el-popconfirm>
								
							</div>
						</div>
					</div>
				</div>
				<!-- 进行中 -->
				<div v-show="current == 2">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									订单类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									购买服务日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">购买方账号</div>
						<div class="item132">订单名称</div>
						<div class="item118">服务金额</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom">
								<span class="el-dropdown-link"> 进行状态 </span>
								<el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">订单号</div>
						<div class="item85">操作</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.calssText }}</div>
							<div class="item118">{{ item.buyTime }}</div>
							<div class="item85">{{ item.buyUserName }}</div>
							<div class="item132">{{ item.buyDemand }}</div>
							<div class="item118">￥{{ item.money }}</div>
							<div class="item118">{{ stateKey[item.state] }}</div>
							<div class="item85">{{ item.id }}</div>
							<div class="item85 blue" style="display: flex">
								<font class="redBlock" v-if="item.redUid != null && item.redUid.includes(info.id)">
								</font>
								<font class="greyBlock" v-else> </font>
								<div @click="readState(item)">查看进度</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 已完结 -->
				<div v-show="current == 3">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									订单类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									购买服务日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">购买方账号</div>
						<div class="item132">订单名称</div>
						<div class="item118">服务金额</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom">
								<span class="el-dropdown-link"> 完结状态 </span>
								<el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">订单号</div>
						<div class="item85">操作</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.calssText }}</div>
							<div class="item118">{{ item.buyTime }}</div>
							<div class="item85">{{ item.buyUserName }}</div>
							<div class="item132">{{ item.buyDemand }}</div>
							<div class="item118">￥{{ item.money }}</div>
							<div class="item118 red">{{ stateKey[item.state] }}</div>
							<div class="item85">{{ item.id }}</div>
							<div class="item85 blue" style="display: flex">
								<font class="redBlock" v-if="item.redUid != null && item.redUid.includes(info.id)">
								</font>
								<font class="greyBlock" v-else> </font>
								<div @click="readState(item)">查看进度</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize"
					@current-change="changePage" :total="shopPage.totalRows">
				</el-pagination>
				<div class="total">共{{ shopPage.totalRows }}件/{{ shopPage.totalPage }}页</div>
				<div class="jump">
					跳转到
					<input type="number" v-model="shopNum" />
					页
				</div>
				<el-button style="
            background: #2ea7e0;
            color: #fff;
            width: 108px;
            height: 50px;
            font-size: 20px;
          " @click="goShopPage">前往</el-button>
			</div>
		</div>
		<SendGood v-if="dialogVisible" :dialogVisible="dialogVisible" :itemInfo="itemInfo" @close="close" />
	</div>
</template>

<script>
	import SendGood from "../../components/serve/sendGood.vue";
	export default {
		components: {
			SendGood
		},
		data() {
			return {
				activeTitle: "first", //echars 图
				activeName: "first", //echars 图
				current: 0, //当前下标
				itemInfo: {}, //点击订单选择的item传参
				value: "", //所有值
				show: false,
				echartsInfoList: [
					//echats所有状态
					{
						id: 1,
						title: "待接单"
					},
					{
						id: 2,
						title: "接单"
					},
					{
						id: 3,
						title: "签署合同"
					},
					{
						id: 4,
						title: "申请付款"
					},
					{
						id: 5,
						title: "服务方工作"
					},
					{
						id: 6,
						title: "验收成果"
					},
					{
						id: 7,
						title: "确认收款信息"
					},
					{
						id: 8,
						title: "交易完成"
					},
					// { id: 8, title: "拒绝接单" },
					{
						id: 9,
						title: "需求异常完结"
					},
				],
				echatsSxList: [
					//echats所有状态
					{
						id: 0,
						title: "待接单"
					},
					{
						id: 1,
						title: "已完结"
					},
					{
						id: 2,
						title: "进行中"
					},
				],
				stateKey: {
					//所有sate状态值
					1: "用户购买",
					2: "店家接单",
					3: "商家上传合同",
					4: "设置金额",
					5: "支付",
					6: "上传工作结果接口",
					7: "确认收货",
					8: "确认收款 交易完成",
					9: "待验收",
				},
				stateKey: {
					1: "待接单",
					2: "待上传合同", //完善店铺详情 待支付保证金
					3: "待填写服务金额", //已支付保证金 待审核
					4: "待需求方支付",
					5: "工作中",
					6: "待验收",
					7: "待确认收款",
					8: "交易完成",
					9: "已拒绝",
					10: "异常完结",
				},
				shopTime: [
					//时间筛选
					{
						id: 0,
						title: "3天内",
						day: 3
					},
					{
						id: 1,
						title: "一周内",
						day: 7
					},
					{
						id: 2,
						title: "一个月内",
						day: 30
					},
					{
						id: 3,
						title: "3个月内",
						day: 90
					},
					{
						id: 4,
						title: "半年内",
						day: 180
					},
					{
						id: 5,
						title: "1年内",
						day: 365
					},
					{
						id: 6,
						title: "3年内",
						day: 1095
					},
				],
				form: {
					//所有订单筛选参数
					state: 0,
					classifyId: 0,
					day: 0,
					pageNo: 1,
				},
				shopNum: "", // 跳转页码
				classList: [], //分类列表
				orderList: [], //订单列表
				shopPage: {}, //分页所有信息
				dialogVisible: false, //订单进度弹出层
				shop: {}, //我的店铺
				klineData: "",
				redInfo: "",
				status1: "",
				status2: "",
				status3: "",
				status4: "",
				info: "",
			};
		},
		mounted() {
			this.getShop();
			this.getClass();
			this.getData();
			this.getRedNum();
			this.getInfo();
		},
		methods: {
			getInfo() {
				this.$util.post("/user/info").then((res) => {
					this.info = res.data;
				});
			},
			//获取我的发布红点统计
			getRedNum() {
				this.$util
					.post("/center/myOrder/buySize", {
						user: "sell",
					})
					.then((res) => {
						this.redInfo = res.data;
						this.status1 = this.redInfo.point1;
						this.status2 = this.redInfo.point2;
						this.status3 = this.redInfo.point3;
						this.status4 = this.redInfo.point4;
					});
			},
			getData() {
				this.$util.post("/shop/statistics").then((res) => {
					this.klineData = res.data;
					this.echartsInit();
					this.echartsSx();
					this.foldLineEcharts();
				});
			},
			remove(id) {
				this.$util
					.post("/center/myOrder/removePurchase", {
						orderId: id,
					})
					.then((res) => {
						if (res.code == 200) {
							this.allData();
							this.$message.success("删除成功");
						} else {
							this.$message.error(res.message);
						}
					});
			},
			close() {
				this.dialogVisible = false;
				// this.allData();
			},
			handleClick(tab) {
				this.current = tab.index;
			},
			getShop() {
				this.$util.post("/shop/myShop").then((res) => {
					this.shop = res.data;
				});
			},
			// 获取所有订单数据
			allData() {
				this.$util.post("/center/shop/order/list", this.form).then((res) => {
					console.log(res);
					res.data.forEach((item) => {
						this.classList.forEach((el) => {
							if (item.classifyId == el.id) {
								this.$set(item, "calssText", el.name);
							}
						});
					});
					this.orderList = res.data;
					this.shopPage = res.pager;
				});
			},
			getClass() {
				this.$util.post("/shop/allClassify").then((res) => {
					this.classList = res.data;
					this.allData();
				});
			},
			// 类型筛选
			getType(item) {
				this.form.classifyId = item.id;
				this.allData();
			},
			// 搜索
			search() {
				(this.form = {
					state: 0,
					classifyId: 0,
					day: 0,
					search: "",
					pageNo: 1,
				}),
				(this.form.search = this.value);
				this.allData();
			},
			goShopPage() {
				this.form.pageNo = this.shopNum;
				this.allData();
			},
			changePage(num) {
				this.form.pageNo = num;
				this.allData();
			},
			// 点击下标跳转
			handleClick(tab) {
				(this.form = {
					state: 0,
					classifyId: 0,
					day: 0,
					search: "",
					pageNo: 1,
				}),
				(this.current = tab.index);
				this.form.state = tab.index;
				this.allData();
			},
			// 时间筛选
			getShopTime(item) {
				this.form.day = item.day;
				this.allData();
				// this.getShopList()
			},
			// 查看进度
			readState(item) {
				// this.state = item.state
				if (item.state == 10) {
					this.$message.error("订单异常完结");
					if (item.redUid != "" && item.redUid != null && item.redUid != undefined) {
						this.$util
							.post("/demandPurchase/reade", {
								orderId: item.id,
							})
							.then((res) => {
								console.log(res);
							});
					}
				} else if (item.state == 9) {
					this.$message.error("订单已拒绝");
					if (item.redUid != "" && item.redUid != null && item.redUid != undefined) {
						this.$util
							.post("/demandPurchase/reade", {
								orderId: item.id,
							})
							.then((res) => {
								console.log(res);
							});
					}
				} else {
					this.itemInfo = item;
					this.dialogVisible = true;
				}
			},
			//柱形图
			echartsInit() {
				var arr = [];
				for (var i in this.klineData.columnChart) {
					console.log(this.klineData.columnChart[i]);
					arr.push(this.klineData.columnChart[i]);
				}
				this.$echarts.init(document.getElementById("flow-chart")).setOption({
					color: [
						"#ffc761",
						"#76bdff",
						"#5383fc",
						"#ec7bda",
						"#44dbe3",
						"#f5525f",
						"#69dfb3",
						"#ff8433",
						"#666666",
					],
					grid: {
						x: 25,
						y: 45,
						x2: 5,
						y2: 25,
						// borderWidth: 1,
					},
					xAxis: {
						type: "category",
						// data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
						data: [],
					},
					yAxis: {
						splitLine: {
							show: false,
						},
						type: "value",
						axisLine: {
							show: true
						},
					},
					series: [{
						barWidth: 18,
						data: arr,
						type: "bar",
						// showBackground: true,
						colorBy: "data",
					}, ],
					tooltip: {
						trigger: "item",
						axisPointer: {
							type: "cross",
							label: {
								backgroundColor: "#6a7985",
							},
						},
					},
				});
			},
			// 饼型
			echartsSx() {
				var arr = [];
				arr.push(this.klineData.pieChart.notStart);
				arr.push(this.klineData.pieChart.over);
				arr.push(this.klineData.pieChart.loading);
				this.$echarts.init(document.getElementById("summary")).setOption({
					color: ["#FFC761", "#FB487C", "#4C7AF3"],
					tooltip: {
						trigger: "item",
					},
					grid: {
						x: 25,
						y: 45,
						x2: 5,
						y2: 25,
						// borderWidth: 1,
					},
					series: [{
						type: "pie",
						center: ["50%", "50%"],
						radius: "90%",
						data: arr,
						label: {
							show: false,
							position: "center",
						},
					}, ],
				});
			},
			// 折线图
			foldLineEcharts() {
				var arr = [];
				var timeArr = [];
				for (var i in this.klineData.timeChart) {
					console.log(this.klineData.timeChart[i]);
					arr.push(this.klineData.timeChart[i].num);
					timeArr.push(this.klineData.timeChart[i].id);
				}
				this.$echarts.init(document.getElementById("foldLine")).setOption({
					grid: {
						x: 50,
						// y: 45,
						x2: 3,
						y2: 30,
						// borderWidth: 1,
					},
					xAxis: {
						type: "category",
						data: timeArr,
						boundaryGap: false,
						axisLabel: {
							interval: 0,
							rotate: 0,
						},
					},
					yAxis: {
						type: "value",
						axisLine: {
							show: true
						},
						splitLine: {
							show: false,
						},
					},
					series: [{
						symbol: "none",
						data: arr,
						type: "line",
						smooth: true,
						areaStyle: {
							normal: {
								color: {
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: "#fde8ea", // 0% 处的颜色
										},
										{
											offset: 1,
											color: "#fde8ea", // 100% 处的颜色
										},
									],
									// globalCoord: false // 缺省为 false
								},
							},
						},
						itemStyle: {
							normal: {
								// color: '#275F82', //改变折线点的颜色
								lineStyle: {
									color: "#e40012", //改变折线颜色
								},
							},
						},
					}, ],
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.greyBlock {
		width: 10px;
		height: 10px;
		background: #999999;
		border-radius: 50%;
		display: block;
		margin-top: 5px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.redBlock {
		width: 10px;
		height: 10px;
		background: #e40012;
		border-radius: 50%;
		display: block;
		margin-top: 5px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.redIcon {
		display: flex;
		justify-content: flex-start;

		div {
			width: 100px;
			display: flex;
			justify-content: right;

			span {
				color: #ffffff;
				text-align: center;
				width: 20px;
				height: 20px;
				font-size: 12px;
				line-height: 20px;
				border-radius: 50%;
				background: #e40012;
			}
		}
	}

	.main-box {
		background: #f6f6f6;
		padding-bottom: 100px;
		// padding: 26px 0 40px;
	}

	.shop-box {
		position: relative;
		// margin: 26px 0 40px;
	}

	.chat-img {
		position: absolute;
		top: 0px;
		right: 160px;
		width: 117px;
		height: 117px;

		// background: red;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.shop-info {
		padding: 26px 20px 30px;
		background: #fff;
		width: 1160px;
		margin: 0 auto;
		display: flex;

		.shop-img {
			width: 198px;
			height: 198px;
			margin-right: 20px;

			// background: #515151;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.shop-title {
			display: flex;
			align-items: center;
			font-size: 14px;
			color: #666;
			margin-bottom: 20px;

			.shop-name {
				font-size: 20px;
				color: #222222;
				margin-right: 10px;
			}

			.shop-tag {
				display: flex;
				margin: 0 80px 0 60px;

				div {
					font-size: 14px;
					color: #666;
					padding-right: 20px;

					span {
						color: #e40012;
					}
				}

				.bor {
					border-right: 1px solid #707070;
					margin-right: 20px;
				}
			}

			.dw {
				width: 19px;
				height: 24px;
				display: block;
				margin: 0 9px 0 32px;
			}
		}

		.shop-business {
			padding: 20px 30px 20px 20px;
			background: #f6f6f6;
			color: #666666;
			margin: 0 0 10px;
		}
	}

	.mian {
		width: 1200px;
		margin: 45px auto 0;
	}

	.order-info {
		display: flex;
		justify-content: space-between;

		.order-number {
			width: 400px;
			display: flex;
			flex-direction: column;

			// justify-content: space-between;
			.quantity {
				padding: 30px;
				background: #fff;
				text-align: center;
				margin-bottom: 30px;

				div {
					display: flex;
					align-items: center;
					font-size: 22px;
					color: #222;

					img {
						margin-right: 10px;
					}
				}

				span {
					font-size: 40px;
					color: #e40012;
					display: block;
					margin: 38px 0 20px;
					font-weight: bold;
				}
			}
		}
	}

	.echarts-box {
		width: 770px;
		display: flex;
		flex-direction: column;
	}

	.process-box {
		height: 410px;
		// padding: 20px;
		background: #fff;

		.tabs {
			margin-bottom: 30px;
			padding: 30px;
		}

		/deep/.el-tabs__item.is-active {
			color: #e40012;
		}

		/deep/.el-tabs__active-bar {
			background-color: #e40012 !important;
			width: 100px; // width: 80px !important;
			display: none;
		}

		/deep/.el-tabs__item {
			font-size: 22px;
			color: #666666;
			// width: 100px;
			height: 50px;
			font-weight: bold;
			text-align: center;
			margin-right: 40px;
		}

		/deep/.el-tabs__item:hover {
			color: #e40012;
		}

		/deep/.el-tabs__header {
			margin: 0;
		}

		.echarts-info {
			display: flex;
			justify-content: space-between;

			.echarts {
				padding-top: 40px;
				width: 600px;
				height: 263px;
			}

			.echarts-data {
				display: flex;
				flex-direction: column;
				font-size: 12px;
				justify-content: flex-end;

				.echarts-data-itemt {
					font-weight: bold;
					display: flex;
					align-items: center;
					margin-top: 18px;
					font-size: 16px;

					div {
						width: 6px;
						height: 6px;
						margin-right: 14px;
					}
				}

				.echarts-data-itemt:nth-child(1) {
					color: #ffc761;

					div {
						background: #ffc761;
					}
				}

				.echarts-data-itemt:nth-child(2) {
					color: #fb487c;

					div {
						background: #fb487c;
					}
				}

				.echarts-data-itemt:nth-child(3) {
					color: #4c7af3;

					div {
						background: #4c7af3;
					}
				}

				.echarts-data-item {
					font-weight: bold;
					display: flex;
					align-items: center;
					margin-top: 18px;

					div {
						width: 6px;
						height: 6px;
						margin-right: 14px;
					}
				}

				.echarts-data-item:nth-child(1) {
					color: #ffc761;

					div {
						background: #ffc761;
					}
				}

				.echarts-data-item:nth-child(2) {
					color: #76bdff;

					div {
						background: #76bdff;
					}
				}

				.echarts-data-item:nth-child(3) {
					color: #5383fc;

					div {
						background: #5383fc;
					}
				}

				.echarts-data-item:nth-child(4) {
					color: #ec7bda;

					div {
						background: #ec7bda;
					}
				}

				.echarts-data-item:nth-child(5) {
					color: #44dbe3;

					div {
						background: #44dbe3;
					}
				}

				.echarts-data-item:nth-child(6) {
					color: #f5525f;

					div {
						background: #f5525f;
					}
				}

				.echarts-data-item:nth-child(7) {
					color: #69dfb3;

					div {
						background: #69dfb3;
					}
				}

				.echarts-data-item:nth-child(8) {
					color: #ff8433;

					div {
						background: #ff8433;
					}
				}

				.echarts-data-item:nth-child(9) {
					color: #666666;

					div {
						background: #666666;
					}
				}
			}
		}
	}

	.month-box {
		height: 408px;
		background: #fff;

		.month-title {
			margin: 0 30px;
			padding: 30px 0;
			border-bottom: 1px solid #f1f1f1;
			font-size: 22px;
			color: #222;
		}
	}

	.main {
		// width: 1140px;
		padding: 30px;
		background: #fff;
		// margin: 0 auto;.

		/deep/.el-tabs__item.is-active {
			color: #e40012;
		}

		/deep/.el-tabs__active-bar {
			background-color: #e40012 !important;
			width: 100px; // width: 80px !important;
		}

		/deep/.el-tabs__item {
			font-size: 22px;
			color: #666666;
			width: 100px;
			height: 50px;
			font-weight: bold;
			text-align: center;
		}

		/deep/.el-tabs__item:hover {
			color: #e40012;
		}

		/deep/.el-tabs__header {
			margin: 0;
		}
	}

	.tabs-nav {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e4e7ed;
		margin-top: -5px;
	}

	.search-l {
		border: 1px solid #999999;
		display: flex;
		color: #fff;
		height: 40px;
		margin-right: 20px;
		font-size: 14px;

		input {
			flex: 1;
			color: #222;
			border: none;
			outline: none;
			height: 100%;
			padding: 0 20px;
		}

		div {
			padding: 10px 30px;
			background: #999999;
			cursor: pointer;
		}
	}

	.cellter {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		color: #222222;
		font-weight: bold;
		text-align: center;

		/deep/.el-dropdown {
			font-size: 16px;
			font-weight: bold;
			color: #222;
		}
	}

	.item85 {
		width: 85px;
		text-align: center;
	}

	.item118 {
		width: 118px;
		text-align: center;
	}

	.item132 {
		width: 132px;
		text-align: center;
	}

	.item75 {
		width: 75px;
		text-align: center;
	}

	.item85 {
		width: 85px;
		text-align: center;
	}

	.item90 {
		width: 90px;
		text-align: center;
	}

	.item171 {
		width: 171px;
		text-align: center;
		display: flex;
		justify-content: center;

		span {
			cursor: pointer;
			margin-right: 20px;
		}

		div {
			cursor: pointer;
		}
	}

	.red {
		color: #e40012;
	}

	.blue {
		color: #2ea7e0;
		cursor: pointer;
	}

	.pink {
		color: #fb487c;
	}

	.cell-box {
		padding: 30px 0 15px;
		border-bottom: 1px solid #eeeeee;
	}

	.cell {
		padding-bottom: 15px;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		color: #666666;
		text-align: center;
	}

	.cell-item {
		padding: 20px;
		background: #f6f6f6;

		.cell-list {
			display: flex;
			margin-bottom: 14px;
			justify-content: space-between;
			color: #666;
			font-size: 14px;
			text-align: center;
		}
	}

	.hui {
		color: #999999;
	}

	.pagination {
		// width: 1200px;
		// margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 60px;
		// border: 1px solid #e3e3e3;
	}

	/deep/.el-pager li {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		border: 1px solid #2ea7e0;
		margin: 0 3px;
		color: #2ea7e0 !important;
	}

	/deep/.el-pagination.is-background .el-pager li {
		background: transparent;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		// border: 1px solid #2ea7e0;
		background: transparent;
		margin: 0 3px;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #fff !important;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 24px;
		color: #2ea7e0;
	}

	/deep/.el-pager li.active+li {
		border: 1px solid #2ea7e0;
	}

	.total {
		color: #2ea7e0;
		font-weight: bold;
		font-size: 16px;
	}

	.jump {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 0 20px 0 40px;
		color: #2ea7e0;

		input {
			width: 40px;
			height: 40px;
			border: 1px solid #0f6c97;
			font-size: 14px;
			color: #2ea7e0;
			outline: none;
			margin: 0 10px;
			border-radius: 4px;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	.main-title {
		font-size: 24px;
		color: #222;
		padding-bottom: 14px;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 30px;
		font-weight: bold;
	}
</style>
